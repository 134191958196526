import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import Row from "components/row"
import SEO from "components/seo"
import Heading from "components/heading"
import Form from "components/form"
import Image from "safe-gatsby-image-plugin"
import { seoDataFromPage } from "components/utils/data-formatting"

import l from "components/layout/layout.module.scss"
import d from "./downloads.module.scss"

const Downloads = ({ pageContext, data: queryData }) => {
  const { title } = pageContext
  const { wordpressPage } = queryData
  const { acf } = wordpressPage
  const {
    wordpress_fields: formFields,
    submit_bt: btnLabel,
    dropdown,
  } = acf.downloads.form

  const meta = seoDataFromPage(wordpressPage)

  const dropDownOptions = dropdown
    ? dropdown.split(",").map(str => str.trim())
    : []

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Row customClass={l.archive_title_section}>
        <div className={d.grid}>
          <div>
            <Heading type="h1" color="white">
              {title}
            </Heading>
            <div dangerouslySetInnerHTML={{ __html: acf.downloads.text }} />
          </div>
          <div className={d.form}>
            <Form
              fields={formFields}
              dropDownOptions={dropDownOptions}
              btnLabel={btnLabel}
              accent="light"
            />
          </div>
        </div>
        <Image data={acf.downloads.image} />
      </Row>
      <Row>
        <div className={d.grid}>
          {acf.downloads.items.map(({ file, title }, i) => (
            <a
              rel="noreferrer"
              key={`download-${i}`}
              className={d.item}
              target="_blank"
              href={
                file.url.localFile
                  ? file.url.localFile.publicURL
                  : file.url.source_url
              }
            >
              <div className={d.icon}></div>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </a>
          ))}
        </div>
      </Row>
    </Layout>
  )
}

export default Downloads

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        downloads {
          text
          image {
            ...ImageFullWidth
          }
          form {
            submit_bt
            dropdown
            wordpress_fields {
              label
              required
              type
              width
            }
          }
          items {
            title
            file {
              url {
                localFile {
                  publicURL
                }
                id
                source_url
              }
            }
          }
        }
      }
      ...SEOData
    }
  }
`
